import React from "react"

import Panel4CSS from "./panel4.module.scss"

import PanelBG from "./panel4-bg"

export default () => (
  <div id="kontakti" className={Panel4CSS.div}>
    <h4 className={Panel4CSS.title}>Individuāls risinājums</h4>
    <p className={Panel4CSS.text}>
      Sadarbībā ar Eiropā pazīstamiem ražotājiem, tagad arī mēs piedāvājam klientiem dažādus jaunus risinājumus, izmantojot kvalitatīvus un dabai draudzīgus materiālus koksnes apdarei. Atliek vien sazināties un pajautāt!
      <PanelBG />
    </p>
  </div>
)
