import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Panel4CSS from "./panel4.module.scss"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "panel4-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 268) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "panel4-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 268) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={Panel4CSS.bgImg}>
      <Img fluid={data.img1.childImageSharp.fluid} className={Panel4CSS.img1} />
      <Img fluid={data.img2.childImageSharp.fluid} className={Panel4CSS.img2} />
    </div>
  )
}

export default Image
