import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Panel4 from "../components/panels/panel4"
import CategoryBoxCSS from "../components/category/category-box.module.scss"

import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const ProductPageTopmost = () => {

const data = useStaticQuery(graphql`
    query {
      box1: file(relativePath: {eq: "olilacke-bg.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      box2: file(relativePath: {eq: "backgrounds/amonn/3_dark.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo1: file(relativePath: {eq: "logo_oli.png"}) {
        childImageSharp {
          fluid(maxWidth: 1212) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo2: file(relativePath: {eq: "logo_amonn.png"}) {
        childImageSharp {
          fluid(maxWidth: 1212) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
  <Layout>
    {/*<SEO title="Home" />*/}

    <Link to="/kategorijas/category-olilacke" className={CategoryBoxCSS.container+" cp"}>
      <Img fluid={data.box1.childImageSharp.fluid} className={CategoryBoxCSS.image} />
      <button><p>Skatīt</p></button>
      <div className={CategoryBoxCSS.titleContainer}>
        <Img fluid={data.logo1.childImageSharp.fluid} className={CategoryBoxCSS.logo} />
        <h2>Oli Lacke</h2>
      </div>
    </Link>

    <Link to="/kategorijas/category-lignex" className={CategoryBoxCSS.container}>
      <Img fluid={data.box2.childImageSharp.fluid} className={CategoryBoxCSS.image} />
      <button><p>Skatīt</p></button>
      <div className={CategoryBoxCSS.titleContainer}>
        <Img fluid={data.logo2.childImageSharp.fluid} className={CategoryBoxCSS.logo} />
        <h2>Amonn Color</h2>
      </div>
    </Link>

    <Panel4 />
  </Layout>

  )

}


export default ProductPageTopmost
